import React, { useState, useEffect, useRef } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Toast } from 'primereact/toast'
import { Button } from 'primereact/button'
import { Toolbar } from 'primereact/toolbar'
import { InputText } from 'primereact/inputtext'
import { LogService } from '../service/LogService'

export const CrudLogs = () => {
  const [logs, setLogs] = useState(null)
  const [globalFilter, setGlobalFilter] = useState(null)
  const toast = useRef(null)
  const dt = useRef(null)

  useEffect(() => {
    const logService = new LogService()
    logService.readLogs().then(data => setLogs(data))
  }, [])

  const exportCSV = () => {
    dt.current.exportCSV()
  }

  const rightToolbarTemplate = () => {
    return (
      <>
        <Button label='Export' icon='pi pi-upload' className='p-button-help' onClick={exportCSV} />
      </>
    )
  }

  const userBodyTemplate = (rowData) => {
    return (
      <>
        <span className='p-column-title'>User</span>
        {rowData.user.firstname + ' ' + rowData.user.lastname}
      </>
    )
  }

  const companyBodyTemplate = (rowData) => {
    return (
      <>
        <span className='p-column-title'>Company</span>
        {rowData.company}
      </>
    )
  }

  const actionBodyTemplate = (rowData) => {
    return (
      <>
        <span className='p-column-title'>Action</span>
        {rowData.action}
      </>
    )
  }

  const typeBodyTemplate = (rowData) => {
    return (
      <>
        <span className='p-column-title'>Type</span>
        {rowData.type}
      </>
    )
  }

  const pathBodyTemplate = (rowData) => {
    return (
      <>
        <span className='p-column-title'>Path</span>
        {rowData.path}
      </>
    )
  }

  const detailBodyTemplate = (rowData) => {
    return (
      <>
        <span className='p-column-title'>Detail</span>
        {rowData.action + JSON.stringify(rowData.detail[0])}
      </>
    )
  }

  const dateBodyTemplate = (rowData) => {
    return (
      <>
        <span className='p-column-title'>Date</span>
        {(new Date(rowData.addDate)).toLocaleString()}
      </>
    )
  }

  const header = (
    <div className='flex flex-column md:flex-row md:justify-content-between md:align-items-center'>
      <h5 className='m-0'>Manage JHControllers</h5>
      <span className='block mt-2 md:mt-0 p-input-icon-left'>
        <i className='pi pi-search' />
        <InputText type='search' onInput={(e) => setGlobalFilter(e.target.value)} placeholder='Search...' />
      </span>
    </div>
  )

  return (
    <div className='grid crud-demo'>
      <div className='col-12'>
        <div className='card'>
          <Toast ref={toast} />
          <Toolbar className='mb-4' right={rightToolbarTemplate} />

          <DataTable
            ref={dt} value={logs}
            dataKey='id' paginator rows={50} rowsPerPageOptions={[50, 100]} className='datatable-responsive'
            paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'
            currentPageReportTemplate='Showing {first} to {last} of {totalRecords} devices'
            globalFilter={globalFilter} emptyMessage='No devices found.' header={header} responsiveLayout='scroll'
          >
            <Column field='user' header='User' sortable body={userBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }} />
            <Column field='action' header='Action' sortable body={actionBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }} />
            <Column field='type' header='Type' sortable body={typeBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }} />
            <Column field='detail' header='Detail' sortable body={detailBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }} />
            <Column field='path' header='Path' sortable body={pathBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }} />
            <Column field='company' header='Company' sortable body={companyBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }} />
            <Column field='date' header='Date' sortable body={dateBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }} />
          </DataTable>

        </div>
      </div>
    </div>
  )
}
