import React, { useState, useEffect, useRef } from 'react'
import classNames from 'classnames'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Toast } from 'primereact/toast'
import { Button } from 'primereact/button'
import { Toolbar } from 'primereact/toolbar'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import { CompanyService } from '../service/CompanyService'
import { UserService } from '../service/UserService'
import { Dropdown } from 'primereact/dropdown'
import { RoleItems } from '../roleItems'
import { transRole } from '../userRoles'
import * as R from 'ramda'

export const CrudUser = () => {
  const emptyUser = {
    id: null,
    email: '',
    firstname: '',
    lastname: '',
    lineId: '',
    role: 0,
    mobile: '',
    password: '',
    companyId: '',
    lang: 'en',
    createdAt: '',
    updatedAt: ''
  }

  const [companyMap, setCompanyMap] = useState(null)
  const [companyDropdown, setCompanyDropdown] = useState(null)
  const [users, setUsers] = useState(null)
  const [userDialog, setUserDialog] = useState(false)
  const [userDetailDialog, setUserDetailDialog] = useState(false)
  const [user, setUser] = useState(emptyUser)
  const [selectedUsers, setSelectedUsers] = useState(null)
  const [submitted, setSubmitted] = useState(false)
  const [globalFilter, setGlobalFilter] = useState(null)
  const toast = useRef(null)
  const dt = useRef(null)

  useEffect(() => {
    const userService = new UserService()
    const companyService = new CompanyService()
    companyService.readCompanies().then(companies => {
      const companyMap = companies.reduce((acc, c) => ({ ...acc, [c.id]: c }), {})
      setCompanyMap(companyMap)
      setCompanyDropdown(Object.keys(companyMap).map(cid => ({ label: companyMap[cid].name, value: companyMap[cid].id })))

      userService.getUsers().then(data => setUsers(data))
    })
  }, [])

  const openNew = () => {
    setUser(emptyUser)
    setSubmitted(false)
    setUserDialog(true)
  }

  const hideDialog = () => {
    setSubmitted(false)
    setUserDialog(false)
  }

  const hideUserDetailDialog = () => {
    setUser(emptyUser)
    setUserDetailDialog(false)
  }

  // TODO
  const saveUser = () => {
    setSubmitted(true)

    const _users = [...users]
    const _user = { ...user }
    const userService = new UserService()
    if (user.id) {
      const index = findIndexById(user.id)

      _users[index] = _user
      userService.updateUser(_user).then(res => {
        // if ()
        // console.log(res)
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'User Updated', life: 3000 })
      }).catch(e => {
        toast.current.show({ severity: 'error', summary: 'Failed', detail: `${e}`, life: 3000 })
      })
    } else {
      // create user
      userService.createUser(_user).then(res => {
        _user.id = res.id
        _users.push(_user)
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'User Created', life: 3000 })
      }).catch(e => {
        toast.current.show({ severity: 'error', summary: 'Failed', detail: `${e}`, life: 3000 })
      })
    }

    setUsers(_users)
    setUserDialog(false)
    setUser(emptyUser)
  }

  const editUser = (user) => {
    setUser({ ...user })
    setUserDialog(true)
  }

  const showUserDetail = (user) => {
    setUser({ ...user })
    setUserDetailDialog(true)
  }

  const findIndexById = (id) => {
    let index = -1
    for (let i = 0; i < users.length; i++) {
      if (users[i].id === id) {
        index = i
        break
      }
    }

    return index
  }

  const exportCSV = () => {
    dt.current.exportCSV()
  }

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || emptyUser[`${name}`]
    const _user = { ...user }
    _user[`${name}`] = val

    setUser(_user)
  }

  const leftToolbarTemplate = () => {
    return (
      <>
        <div className='my-2'>
          <Button label='New' icon='pi pi-plus' className='p-button-success mr-2' onClick={openNew} />
        </div>
      </>
    )
  }

  const rightToolbarTemplate = () => {
    return (
      <>
        <Button label='Export' icon='pi pi-upload' className='p-button-help' onClick={exportCSV} />
      </>
    )
  }

  const idBodyTemplate = (rowData) => {
    return (
      <>
        <span className='p-column-title'>Id</span>
        {rowData.id}
      </>
    )
  }
  const nameBodyTemplate = (rowData) => {
    return (
      <>
        <span className='p-column-title'>Name</span>
        {rowData.firstname + ' ' + rowData.lastname}
      </>
    )
  }

  const emailBodyTemplate = (rowData) => {
    return (
      <>
        <span className='p-column-title'>Email</span>
        {rowData.email}
      </>
    )
  }

  const companyBodyTemplate = (rowData) => {
    return (
      <>
        <span className='p-column-title'>Company</span>
        {companyMap[rowData.companyId] ? companyMap[rowData.companyId].name : ''}
      </>
    )
  }

  const actionBodyTemplate = (rowData) => {
    return (
      <div className='actions'>
        <Button icon='pi pi-info-circle' className='p-button-rounded p-button-info mr-2' onClick={() => showUserDetail(rowData)} />
        <Button icon='pi pi-pencil' className='p-button-rounded p-button-success mr-2' onClick={() => editUser(rowData)} />
      </div>
    )
  }

  const header = (
    <div className='flex flex-column md:flex-row md:justify-content-between md:align-items-center'>
      <h5 className='m-0'>Manage Users</h5>
      <span className='block mt-2 md:mt-0 p-input-icon-left'>
        <i className='pi pi-search' />
        <InputText type='search' onInput={(e) => setGlobalFilter(e.target.value)} placeholder='Search...' />
      </span>
    </div>
  )

  const userDialogFooter = (
    <>
      <Button label='Cancel' icon='pi pi-times' className='p-button-text' onClick={hideDialog} />
      <Button label='Save' icon='pi pi-check' className='p-button-text' onClick={saveUser} />
    </>
  )

  const parseDate = (unixTime) => {
    if (R.isNil(unixTime)) return ''
    return new Date(unixTime).toLocaleDateString()
  }

  return (
    <div className='grid crud-demo'>
      <div className='col-12'>
        <div className='card'>
          <Toast ref={toast} />
          <Toolbar className='mb-4' left={leftToolbarTemplate} right={rightToolbarTemplate} />

          <DataTable
            ref={dt} value={users} selection={selectedUsers} onSelectionChange={(e) => setSelectedUsers(e.value)}
            dataKey='id' paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className='datatable-responsive'
            paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'
            currentPageReportTemplate='Showing {first} to {last} of {totalRecords} users'
            globalFilter={globalFilter} emptyMessage='No users found.' header={header} responsiveLayout='scroll'
          >
            <Column selectionMode='multiple' headerStyle={{ width: '3rem' }} />
            <Column field='id' header='id' sortable body={idBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }} />
            <Column field='name' header='Name' sortable body={nameBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }} />
            {/* <Column field='role' header='Role' sortable body={roleBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }} /> */}
            <Column field='company' header='Company' sortable body={companyBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }} />
            <Column field='email' header='Email' sortable body={emailBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }} />
            {/* <Column field='mobile' header='Mobile' sortable body={mobileBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }} /> */}
            {/* <Column field='lineId' header='LineId' sortable body={lineIdBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }} /> */}
            {/* <Column field='lang' header='Lang' sortable body={langBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }} /> */}
            <Column body={actionBodyTemplate} />
          </DataTable>

          <Dialog visible={userDialog} style={{ width: '450px' }} header='User Details' modal className='p-fluid' footer={userDialogFooter} onHide={hideDialog}>
            <div className='field'>
              <label htmlFor='email'>Email</label>
              <InputText id='email' value={user.email} onChange={(e) => onInputChange(e, 'email')} required autoFocus className={classNames({ 'p-invalid': submitted && !user.email })} />
              {submitted && !user.email && <small className='p-invalid'>Email is required.</small>}
            </div>
            <div className='field'>
              <label htmlFor='firstname'>First Name</label>
              <InputText id='firstname' value={user.firstname} onChange={(e) => onInputChange(e, 'firstname')} required autoFocus className={classNames({ 'p-invalid': submitted && !user.firstname })} />
              {submitted && !user.firstname && <small className='p-invalid'>Firstname is required.</small>}
            </div>
            <div className='field'>
              <label htmlFor='lastname'>Last Name</label>
              <InputText id='lastname' value={user.lastname} onChange={(e) => onInputChange(e, 'lastname')} required autoFocus className={classNames({ 'p-invalid': submitted && !user.lastname })} />
              {submitted && !user.lastname && <small className='p-invalid'>lastname is required.</small>}
            </div>
            <div className='field'>
              <label htmlFor='company'>Company</label>
              <Dropdown
                id='companyid' value={user.companyId}
                options={companyDropdown}
                onChange={(e) => onInputChange(e, 'companyId')} required autoFocus
                className={classNames({ 'p-invalid': submitted && !user.companyId })}
              />
            </div>
            <div className='field'>
              <label htmlFor='role'>Role</label>
              <Dropdown
                id='role' value={user.role}
                options={RoleItems}
                onChange={(e) => onInputChange(e, 'role')} required autoFocus
                className={classNames({ 'p-invalid': submitted && !R.isNil(user.role) })}
              />
            </div>
            <div className='field'>
              <label htmlFor='lineId'>Line Id</label>
              <InputText
                id='lineId' value={user.lineId} onChange={(e) => onInputChange(e, 'lineId')} autoFocus
                className={classNames({ 'p-invalid': submitted && !user.lineId })}
              />
            </div>
            <div className='field'>
              <label htmlFor='mobile'>Mobile</label>
              <InputText
                id='mobile' value={user.mobile} onChange={(e) => onInputChange(e, 'mobile')} autoFocus
                className={classNames({ 'p-invalid': submitted && !user.mobile })}
              />
            </div>
            {R.isNil(user.id)
              ? (
                <div className='field'>
                  <label htmlFor='mobile'>Password</label>
                  <InputText
                    id='mobile' value={user.password} onChange={(e) => onInputChange(e, 'password')} autoFocus
                    className={classNames({ 'p-invalid': submitted && !user.possword })}
                  />
                </div>
                )
              : (<div />)}
          </Dialog>

          <Dialog visible={userDetailDialog} style={{ width: '450px' }} header='Company Detail' modal className='p-fluid' onHide={hideUserDetailDialog}>
            <div className='field'>
              <label htmlFor='firstname'>First Name: </label><label>{user.firstname}</label>
            </div>
            <div className='field'>
              <label htmlFor='lastname'>Last Name: </label><label>{user.lastname}</label>
            </div>
            <div className='field'>
              <label htmlFor='email'>Email: </label><label>{user.email}</label>
            </div>
            <div className='field'>
              <label htmlFor='company'>Company: </label>
              <label>{user.companyId ? (companyMap[user.companyId] ? companyMap[user.companyId].name : '') : ''}</label>
            </div>
            <div className='field'>
              <label htmlFor='role'>Role: </label><label>{user.role + ' ' + transRole(user.role)}</label>
            </div>
            <div className='field'>
              <label htmlFor='mobile'>Mobile: </label><label>{user.mobile}</label>
            </div>
            <div className='field'>
              <label htmlFor='lineid'>Line Id: </label><label>{user.lineId}</label>
            </div>
            <div className='field'>
              <label htmlFor='createdat'>Created At: </label><label>{parseDate(user.createdAt)}</label>
            </div>
            <div className='field'>
              <label htmlFor='updatedat'>Updated At: </label><label>{parseDate(user.updatedAt)}</label>
            </div>
            <div className='field'>
              <label htmlFor='expired'>Expired Date: </label><label>{parseDate(user.expireDate)}</label>
            </div>
          </Dialog>
        </div>
      </div>
    </div>
  )
}
