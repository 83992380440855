import axios from 'axios'
import env from '../env'

export class LogService {
  constructor () {
    const store = JSON.parse(window.localStorage.getItem('calyx-tools') || '{}')
    this.token = store.token
  }

  async readLogs () {
    return axios.get(env.apiDomain + '/apiv2/logs', { headers: { Authorization: this.token } })
      .then(res => {
        return res.data
      })
  }
}
