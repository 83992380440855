export const RoleItems = [
  { value: 0, label: 'God' },
  { value: 1, label: 'Free Visitor' },
  { value: 2, label: 'Free Admin' },
  { value: 3, label: 'Standard Visitor' },
  { value: 4, label: 'Standard Admin' },
  { value: 5, label: 'Standard Super Admin' },
  { value: 6, label: 'Pro Visitor' },
  { value: 7, label: 'Pro Admin' },
  { value: 8, label: 'Pro Super Admin' },
  { value: 9, label: 'Trail Visitor' },
  { value: 10, label: 'Trail Admin' },
  { value: 11, label: 'Trail Super Admin' }
]
