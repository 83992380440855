import axios from 'axios'
import { highlight } from 'prismjs'
import env from '../env'

const encodePkg11 = (payload) => {
  // NOTE regularMeasurementInterval, statusReportInterval, sleepN1, sleepN2
  const buffer = new ArrayBuffer(6)
  const headerView = new DataView(buffer, 0, 1)
  const regularMeasurementInterval = new DataView(buffer, 1, 1)
  const statusReportInterval = new DataView(buffer, 2, 2)
  const sleepN1View = new DataView(buffer, 4, 1)
  const sleepN2View = new DataView(buffer, 5, 1)

  headerView.setUint8(0, 11)
  regularMeasurementInterval.setUint8(0, payload.regularMeasurementInterval)
  // [high, low]
  // NOTE 文件上寫 little-Endian，但實際上不用
  // statusReportInterval.setUint16(0, payload.statusReportInterval, true)
  statusReportInterval.setUint16(0, payload.statusReportInterval)
  sleepN1View.setUint8(0, payload.sleepN1)
  sleepN2View.setUint8(0, payload.sleepN2)
  return Array.from(new Int8Array(buffer))
}

const encodePkg12 = (payload) => {
  const buffer = new ArrayBuffer(2)
  const headerView = new DataView(buffer, 0, 1)
  const nh3ThresholdView = new DataView(buffer, 1, 1)
  headerView.setUint8(0, 12)
  nh3ThresholdView.setUint8(0, payload.nh3Threshold)
  return Array.from(new Int8Array(buffer))
}

const encodePkg13 = (payload) => {
  const buffer = new ArrayBuffer(2)
  const headerView = new DataView(buffer, 0, 1)
  const temperatureThresholdView = new DataView(buffer, 1, 1)
  headerView.setUint8(0, 13)
  temperatureThresholdView.setUint8(0, payload.temperatureThreshold)
  return Array.from(new Int8Array(buffer))
}

const encodePkg14 = (payload) => {
  const buffer = new ArrayBuffer(2)
  const headerView = new DataView(buffer, 0, 1)
  const co2ThresholdView = new DataView(buffer, 1, 1)
  headerView.setUint8(0, 14)
  co2ThresholdView.setUint8(0, payload.co2Threshold)
  return Array.from(new Int8Array(buffer))
}

const encodePkg15 = (payload) => {
  const buffer = new ArrayBuffer(2)
  const headerView = new DataView(buffer, 0, 1)
  const aneometerThresholdView = new DataView(buffer, 1, 1)
  headerView.setUint8(0, 15)
  aneometerThresholdView.setUint8(0, payload.aneometerThreshold)
  return Array.from(new Int8Array(buffer))
}

const encodePkg16 = (payload) => {
  const buffer = new ArrayBuffer(4)
  const headerView = new DataView(buffer, 0, 1)
  const sensorATypeView = new DataView(buffer, 1, 1)
  const sensorBTypeView = new DataView(buffer, 2, 1)
  const sensorCTypeView = new DataView(buffer, 3, 1)
  headerView.setUint8(0, 16)
  sensorATypeView.setUint8(0, payload.sensorAType)
  sensorBTypeView.setUint8(0, payload.sensorBType)
  sensorCTypeView.setUint8(0, payload.sensorCType)
  return Array.from(new Int8Array(buffer))
}

const encodePkg5 = (payload) => {
  const buffer = new ArrayBuffer(1)
  const headerView = new DataView(buffer, 0, 1)
  headerView.setUint8(0, 5)
  return Array.from(new Int8Array(buffer))
}

const encodePkg6 = (payload) => {
  const buffer = new ArrayBuffer(1)
  const headerView = new DataView(buffer, 0, 1)
  headerView.setUint8(0, 6)
  return Array.from(new Int8Array(buffer))
}

const encodePayload = (packageType, payload) => {
  switch (packageType) {
    case 'pkg5':
      return encodePkg5(payload)
    case 'pkg6':
      return encodePkg6(payload)
    case 'pkg11':
      return encodePkg11(payload)
      // return [11, payload.regularMeasurementInterval, payload.statusReportInterval, payload.sleepN1, payload.sleepN2]
    case 'pkg12':
      return encodePkg12(payload)
      // return [12, payload.nh3Threshold]
    case 'pkg13':
      return encodePkg13(payload)
      // return [13, payload.temperatureThreshold]
    case 'pkg14':
      return encodePkg14(payload)
      // return [14, payload.co2Threshold]
    case 'pkg15':
      return encodePkg15(payload)
      // return [15, payload.aneometerThreshold]
    case 'pkg16':
      return encodePkg16(payload)
      // return [16, payload.sensorAType, payload.sensorBType, payload.sensorCType]
  }
}

export class DeviceService {
  constructor () {
    const store = JSON.parse(window.localStorage.getItem('calyx-tools') || '{}')
    this.token = store.token
  }

  async readDevices () {
    return axios.get(env.apiDomain + '/apiv2/devices', { headers: { Authorization: this.token } })
      .then(res => {
        return res.data
      })
  }

  async createDevice (device) {
    Object.keys(device).forEach(key => (device[key] === undefined || device[key] === '') && delete device[key])
    return axios.post(env.apiDomain + '/apiv2/devices', {
      companyId: device.companyId,
      service: device.service,
      hardware_serial: device.hardware_serial,
      gateway: device.gateway,
      serial_number: device.serial_number
    }, { headers: { Authorization: this.token } })
      .then(res => {
        return res.data
      })
  }

  async updateDevice (device) {
    return axios.patch(env.apiDomain + `/apiv2/devices/${device.id}`, {
      companyId: device.companyId,
      service: device.service,
      hardware_serial: device.hardware_serial,
      gaweway: device.gateway,
      remark: device.remark,
      company: device.company
    }, { headers: { Authorization: this.token } })
      .then(res => {
        return res.data
      })
  }

  async unbindDevice (device, positionId) {
    return axios.post(env.apiDomain + `/apiv2/${device.companyId}/devices/${device.id}/unbind`, {
      companyId: device.companyId,
      positionId: positionId
    }, { headers: { Authorization: this.token } })
      .then(res => {
        return res.data
      })
  }

  async getCurrentDeviceSetting (device) {
    return axios.post(env.apiDomain + '/apiv2/devices/packages', {
      hardware_serial: device.hardware_serial
    }, { headers: { Authorization: this.token } })
      .then(res => {
        return res.data
      })
  }

  async downlink (device, packageType, payload, callback) {
    if (device.hardware_serial) {
      const encodedPayload = encodePayload(packageType, payload)
      callback(encodedPayload)
      return axios.post(env.apiDomain + '/api/devices/downlink', {
        hardware_serial: `${device.hardware_serial}`,
        payload: encodedPayload
      }, { headers: { Authorization: this.token } })
        .then(res => {
          return res.data
        })
    } else {
      console.warn('no hardware_serial')
      return {
        errorCode: -1
      }
    }
  }
}
