import React, { useState, useEffect, useRef } from 'react'
import classNames from 'classnames'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Toast } from 'primereact/toast'
import { Button } from 'primereact/button'
import { FileUpload } from 'primereact/fileupload'
import { Toolbar } from 'primereact/toolbar'
import { RadioButton } from 'primereact/radiobutton'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import { JHControllerService } from '../service/JHControllerService'
import { CompanyService } from '../service/CompanyService'

export const CrudJHController = () => {
  const emptyJHController = {
    id: null,
    name: '',
    image: null,
    description: '',
    category: null,
    price: 0,
    quantity: 0,
    rating: 0,
    inventoryStatus: 'INSTOCK'
  }

  const [devices, setJHControllers] = useState(null)
  const [companyMap, setCompanyMap] = useState(null)
  const [deviceDialog, setJHControllerDialog] = useState(false)
  const [device, setJHController] = useState(emptyJHController)
  const [selectedJHControllers, setSelectedJHControllers] = useState(null)
  const [submitted, setSubmitted] = useState(false)
  const [globalFilter, setGlobalFilter] = useState(null)
  const toast = useRef(null)
  const dt = useRef(null)

  useEffect(() => {
    const deviceService = new JHControllerService()
    const companyService = new CompanyService()
    companyService.readCompanies().then(companies => {
      const companyMap = companies.reduce((acc, c) => ({ ...acc, [c.id]: c }), {})
      console.log(companyMap)
      setCompanyMap(companyMap)
      deviceService.readJHControllers().then(data => setJHControllers(data))
    })
  }, [])

  const openNew = () => {
    setJHController(emptyJHController)
    setSubmitted(false)
    setJHControllerDialog(true)
  }

  const hideDialog = () => {
    setSubmitted(false)
    setJHControllerDialog(false)
  }

  const saveJHController = () => {
    setSubmitted(true)

    if (device.name.trim()) {
      const _devices = [...devices]
      const _device = { ...device }
      if (device.id) {
        const index = findIndexById(device.id)

        _devices[index] = _device
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'JHController Updated', life: 3000 })
      } else {
        _device.id = createId()
        _device.image = 'device-placeholder.svg'
        _devices.push(_device)
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'JHController Created', life: 3000 })
      }

      setJHControllers(_devices)
      setJHControllerDialog(false)
      setJHController(emptyJHController)
    }
  }

  const findIndexById = (id) => {
    let index = -1
    for (let i = 0; i < devices.length; i++) {
      if (devices[i].id === id) {
        index = i
        break
      }
    }

    return index
  }

  const createId = () => {
    let id = ''
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    for (let i = 0; i < 5; i++) {
      id += chars.charAt(Math.floor(Math.random() * chars.length))
    }
    return id
  }

  const exportCSV = () => {
    dt.current.exportCSV()
  }

  const onCategoryChange = (e) => {
    const _device = { ...device }
    _device.category = e.value
    setJHController(_device)
  }

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || ''
    const _device = { ...device }
    _device[`${name}`] = val

    setJHController(_device)
  }

  const leftToolbarTemplate = () => {
    return (
      <>
        <div className='my-2'>
          {/* <Button label='New' icon='pi pi-plus' className='p-button-success mr-2' onClick={openNew} /> */}
        </div>
      </>
    )
  }

  const rightToolbarTemplate = () => {
    return (
      <>
        {/* <FileUpload mode='basic' accept='image/*' maxFileSize={1000000} label='Import' chooseLabel='Import' className='mr-2 inline-block' /> */}
        <Button label='Export' icon='pi pi-upload' className='p-button-help' onClick={exportCSV} />
      </>
    )
  }

  const idBodyTemplate = (rowData) => {
    return (
      <>
        <span className='p-column-title'>Code</span>
        {rowData._id}
      </>
    )
  }

  const nameBodyTemplate = (rowData) => {
    return (
      <>
        <span className='p-column-title'>Name</span>
        {rowData.name}
      </>
    )
  }

  const remarkBodyTemplate = (rowData) => {
    return (
      <>
        <span className='p-column-title'>Remark</span>
        {rowData.remark}
      </>
    )
  }

  const companyBodyTemplate = (rowData) => {
    return (
      <>
        <span className='p-column-title'>Company</span>
        {rowData.company}
      </>
    )
  }

  const serviceBodyTemplate = (rowData) => {
    return (
      <>
        <span className='p-column-title'>Service</span>
        {rowData.service}
      </>
    )
  }

  const hardwareSerialBodyTemplate = (rowData) => {
    return (
      <>
        <span className='p-column-title'>Hardware Serial</span>
        {rowData.hardware_serial}
      </>
    )
  }

  const actionBodyTemplate = (rowData) => {
    return (
      <div className='actions'>
        {/*                 <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editJHController(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDeleteJHController(rowData)} /> */}
      </div>
    )
  }

  const header = (
    <div className='flex flex-column md:flex-row md:justify-content-between md:align-items-center'>
      <h5 className='m-0'>Manage JHControllers</h5>
      <span className='block mt-2 md:mt-0 p-input-icon-left'>
        <i className='pi pi-search' />
        <InputText type='search' onInput={(e) => setGlobalFilter(e.target.value)} placeholder='Search...' />
      </span>
    </div>
  )

  const deviceDialogFooter = (
    <>
      <Button label='Cancel' icon='pi pi-times' className='p-button-text' onClick={hideDialog} />
      <Button label='Save' icon='pi pi-check' className='p-button-text' onClick={saveJHController} />
    </>
  )

  return (
    <div className='grid crud-demo'>
      <div className='col-12'>
        <div className='card'>
          <Toast ref={toast} />
          <Toolbar className='mb-4' left={leftToolbarTemplate} right={rightToolbarTemplate} />

          <DataTable
            ref={dt} value={devices} selection={selectedJHControllers} onSelectionChange={(e) => setSelectedJHControllers(e.value)}
            dataKey='id' paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className='datatable-responsive'
            paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'
            currentPageReportTemplate='Showing {first} to {last} of {totalRecords} devices'
            globalFilter={globalFilter} emptyMessage='No devices found.' header={header} responsiveLayout='scroll'
          >
            <Column selectionMode='multiple' headerStyle={{ width: '3rem' }} />
            <Column field='id' header='id' sortable body={idBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }} />
            <Column field='remark' header='remark' sortable body={remarkBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }} />
            <Column field='name' header='Name' sortable body={nameBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }} />
            <Column field='company' header='company' sortable body={companyBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }} />
            <Column field='service' header='service' sortable body={serviceBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }} />
            <Column field='hardware_serial' header='hardware_serial' sortable body={hardwareSerialBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }} />
            <Column body={actionBodyTemplate} />
          </DataTable>

          <Dialog visible={deviceDialog} style={{ width: '450px' }} header='JHController Details' modal className='p-fluid' footer={deviceDialogFooter} onHide={hideDialog}>

            <div className='field'>
              <label htmlFor='name'>Remark</label>
              <InputText id='name' value={device.remark} onChange={(e) => onInputChange(e, 'remark')} required autoFocus className={classNames({ 'p-invalid': submitted && !device.remark })} />
              {submitted && !device.remark && <small className='p-invalid'>Name is required.</small>}
            </div>

            <div className='field'>
              <label htmlFor='name'>Name</label>
              <InputText id='name' value={device.name} onChange={(e) => onInputChange(e, 'name')} required autoFocus className={classNames({ 'p-invalid': submitted && !device.name })} />
              {submitted && !device.name && <small className='p-invalid'>Name is required.</small>}
            </div>
            {/*
            <div className='field'>
              <label htmlFor='description'>Description</label>
              <InputTextarea id='description' value={device.description} onChange={(e) => onInputChange(e, 'description')} required rows={3} cols={20} />
            </div>
            */}
            <div className='field'>
              <label className='mb-3'>Service</label>
              <div className='formgrid grid'>
                <div className='field-radiobutton col-6'>
                  <RadioButton inputId='category1' name='category' value='pigfarm' onChange={onCategoryChange} checked={device.category === 'pigfarm'} />
                  <label htmlFor='category1'>pigfarm</label>
                </div>
                <div className='field-radiobutton col-6'>
                  <RadioButton inputId='category2' name='category' value='farm' onChange={onCategoryChange} checked={device.category === 'farm'} />
                  <label htmlFor='category2'>farm</label>
                </div>
                <div className='field-radiobutton col-6'>
                  <RadioButton inputId='category3' name='category' value='sensingdatas' onChange={onCategoryChange} checked={device.category === 'sensingdatas'} />
                  <label htmlFor='category3'>sensingdatas</label>
                </div>
                <div className='field-radiobutton col-6'>
                  <RadioButton inputId='category4' name='category' value='calyxsensingdatas' onChange={onCategoryChange} checked={device.category === 'calyxsensingdatas'} />
                  <label htmlFor='category4'>calyxsensingdatas</label>
                </div>
              </div>
            </div>

            {/*
            <div className='formgrid grid'>
              <div className='field col'>
                <label htmlFor='price'>Price</label>
                <InputNumber id='price' value={device.price} onValueChange={(e) => onInputNumberChange(e, 'price')} mode='currency' currency='USD' locale='en-US' />
              </div>
              <div className='field col'>
                <label htmlFor='quantity'>Quantity</label>
                <InputNumber id='quantity' value={device.quantity} onValueChange={(e) => onInputNumberChange(e, 'quantity')} integeronly />
              </div>
            </div>
            */}
          </Dialog>
        </div>
      </div>
    </div>
  )
}
