import React, { useState } from 'react'

import { LoginService } from '../service/LoginService'
import { Logo } from '../components/logo'
import { useStore } from '../stores/GlobalStore'

import '../assets/login.css'

export const Login = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState(null)
  const [submit, setSubmit] = useState(false)
  const { state, dispatch } = useStore()

  const dismissError = () => {}

  const handleUserChange = (e) => {
    setEmail(e.target.value)
  }
  const handlePassChange = (e) => {
    setPassword(e.target.value)
  }

  const handleSubmit = (e) => {
    console.log(`email = ${email} password = ${password}`)
    if (submit) return
    setSubmit(true)
    const loginService = new LoginService()
    loginService.login(email, password).then(res => {
      console.log(res)
      if (res.status === 200) {
        // login success
        // console.log(`login success, current state ${state}`)
        dispatch({
          type: 'LOGIN',
          payload: res.data,
          callback: () => { console.log(`[callback]login success, state = ${JSON.stringify(state)}`) }
        })
      } else {
        setSubmit(false)
      }
    })
    e.preventDefault()
  }

  return (
    <div className='bg-login'>
      <div className='row login h-100'>
        <div className='col-lg-6'>
          <div className='text-center'>
            <Logo />
          </div>
        </div>
        <div className='col-lg-6'>
          <div className='login-form'>
            <form onSubmit={handleSubmit}>
              {
            error &&
              <h3 data-test='error' onClick={dismissError}>
                <button onClick={dismissError}>✖</button>
                {error}
              </h3>
            }
              {/* <label>User Name</label> */}
              <input className='my-2 form-control' placeholder='Email' type='text' data-test='email' value={email} onChange={handleUserChange} />

              {/* <label>Password</label> */}
              <input className='my-2 form-control' placeholder='Password' type='password' data-test='password' value={password} onChange={handlePassChange} />
              <br />
              {/* <input className="my-1 btn mt-4 default" type='submit' value='Log In' data-test='submit' /> */}
              <button className='my-1 btn mt-4 default'>Login</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
