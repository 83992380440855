import axios from 'axios'
import env from '../env'

export class EControllerService {
  constructor () {
    const store = JSON.parse(window.localStorage.getItem('calyx-tools') || '{}')
    this.token = store.token
  }

  async createEController (company) {
    return axios.post(env.apiDomain + '/api/eControllers', {
      service: company.service,
      name: company.name,
      isDealer: company.isDealer
    }, { headers: { Authorization: this.token } })
      .then(res => {
        return res.data
      })
  }

  async readEControllers () {
    return axios.get(env.apiDomain + '/api/eControllers', { headers: { Authorization: this.token } })
      .then(res => {
        console.log(res)
        return res.data
      })
  }

  async updateEController (company) {
    return axios.patch(env.apiDomain + `/api/eControllers?_id=${company.id}`, {
      service: company.service,
      name: company.name,
      isDealer: company.isDealer
    }, { headers: { Authorization: this.token } })
      .then(res => {
        return res.data
      })
  }
}
