import React from 'react'

export const idBodyTemplate = (rowData) => {
  return (
    <>
      <span className='p-column-title'>Id</span>
      {rowData.id}
    </>
  )
}
export const nameBodyTemplate = (rowData) => {
  return (
    <>
      <span className='p-column-title'>Name</span>
      {rowData.name}
    </>
  )
}

export const isDealerBodyTemplate = (rowData) => {
  return (
    <>
      <span className='p-column-title'>Is Dealer</span>
      {rowData.isdealer ? 'True' : 'False'}
    </>
  )
}

export const serviceBodyTemplate = (rowData) => {
  return (
    <>
      <span className='p-column-title'>Service</span>
      {rowData.service}
    </>
  )
}

export const createTimeBodyTemplate = (rowData) => {
  return (
    <>
      <span className='p-column-title'>Create Time</span>
      {rowData.createdAt}
    </>
  )
}
export const updateTimeBodyTemplate = (rowData) => {
  return (
    <>
      <span className='p-column-title'>Update Time</span>
      {rowData.updatedAt}
    </>
  )
}

export const trailBodyTemplate = (rowData) => {
  return (
    <>
      <span className='p-column-title'>Trrail</span>
      <span className={rowData.isTrail ? 'pi pi-check' : 'pi pi-times'}> </span>
    </>
  )
}
