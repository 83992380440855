import React from 'react'

export const Logo = () => {
  return (
    <svg
      className='Logo' version='1.1' id='Layer_2' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' x='0px' y='0px'
      viewBox='0 0 110.3 113.7' xmlSpace='preserve'
    >
      <g>
        <g>
          <g>
            <g>
              <g>
                <path
                  className='st0' d='M101.8,11.1c0-2.3,1.9-4.2,4.3-4.2c2.4,0,4.3,1.8,4.3,4.2c0,2.3-1.9,4.2-4.3,4.2
						C103.7,15.2,101.8,13.4,101.8,11.1z M109.5,11.1c0-1.9-1.5-3.4-3.4-3.4c-1.9,0-3.4,1.5-3.4,3.4s1.5,3.4,3.4,3.4
						C108,14.5,109.5,13,109.5,11.1z M104.5,13V8.9h1.9c0.8,0,1.4,0.5,1.4,1.3c0,0.7-0.4,1.1-1,1.2L108,13h-0.9l-1.1-1.6h-0.9V13
						H104.5z M105.2,10.9h1c0.5,0,0.8-0.3,0.8-0.7c0-0.4-0.3-0.7-0.8-0.7h-1V10.9z'
                />
              </g>
            </g>
          </g>
        </g>
        <path className='st0' d='M70.7,51.2C96.2,24.8,97,6.9,97,6.9H65C65,15,60.6,26,54.4,34.4C59.3,40.1,65.2,45.4,70.7,51.2z' />
        <g>
          <path
            className='st1' d='M26.7,62.4c0,0-0.1-0.1-0.1-0.1C-0.3,90,0,106.9,0,106.9h32.4c0-9,5.4-18.6,12.3-27.7
			C37.8,73.9,30.9,66.2,26.7,62.4z'
          />
        </g>
        <g>
          <path
            className='st2' d='M97,106.9C89.8,66,35.3,42.5,32.9,6.9c0,0-32.3,0-32.3,0C1.5,18.1,13,36.3,30.9,54.7
			c2.5,2.4,14.7,14.7,19,20.5l0,0C57.6,84.9,65,96.9,65,106.9H97z'
          />
        </g>
      </g>
      <path className='st0' d='M0,0' />
      <path className='st0' d='M110.3,0' />
      <path className='st0' d='M110.3,113.7' />
      <path className='st0' d='M0,113.7' />
    </svg>
  )
}
