import axios from 'axios'
import env from '../env'

export class LoginService {
  login (email, password) {
    return axios.post(env.apiDomain + '/apiv2/auth/signin', {
      email: email,
      password: password
    })
  }
}
