import axios from 'axios'
import env from '../env'

export class CompanyService {
  constructor () {
    const store = JSON.parse(window.localStorage.getItem('calyx-tools') || '{}')
    this.token = store.token
  }

  async readCompanies () {
    return axios.get(env.apiDomain + '/apiv2/companies', { headers: { Authorization: this.token } })
      .then(res => {
        return res.data
      })
  }

  async createCompany (company) {
    Object.keys(company).forEach(key => company[key] === undefined && delete company[key])
    return axios.post(env.apiDomain + '/apiv2/companies', {
      service: company.service,
      name: company.name,
      isDealer: company.isDealer
    }, { headers: { Authorization: this.token } })
      .then(res => {
        return res.data
      })
  }

  async updateCompany (company) {
    Object.keys(company).forEach(key => company[key] === undefined && delete company[key])
    return axios.patch(env.apiDomain + `/apiv2/companies/${company.id}`,
      company,
      { headers: { Authorization: this.token } })
      .then(res => {
        return res.data
      })
  }
}
