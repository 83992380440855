import React, { useState, useEffect, useRef } from 'react'
import classNames from 'classnames'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Toast } from 'primereact/toast'
import { Button } from 'primereact/button'
import { FileUpload } from 'primereact/fileupload'
import { Toolbar } from 'primereact/toolbar'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import { DeviceService } from '../service/DeviceService'
import { CompanyService } from '../service/CompanyService'
import { PositionService } from '../service/PositionService'
import { Dropdown } from 'primereact/dropdown'
import { Slider } from 'primereact/slider'
import { TabView, TabPanel } from 'primereact/tabview'
import * as R from 'ramda'

export const CrudDevice = () => {
  const emptyDevice = {
    id: null,
    name: '',
    remark: '',
    serial_number: '',
    hardware_serial: '',
    service: '', // will be deprecated
    positionId: undefined,
    companyId: '',
    gateway: undefined, // some record is undefined
    company: ''
  }

  const emptyDownlink = {
    // Adjust Interval
    pkg5: { payload: [], lora_time: 0 },
    pkg6: { payload: [], lora_time: 0 },
    pkg7: { payload: [], lora_time: 0 },
    pkg11: { // ref uplink pkg7
      regularMeasurementInterval: 15, // 0 ~ 255 minute
      statusReportInterval: 15, // 0 ~ 65536 minute Big-Endian
      sleepN1: 15, // 0 ~ 255
      sleepN2: 15 // 0 ~ 255
    },
    pkg12: { // ref uplink pkg6: nh3_offset_threshold
      nh3Threshold: 100 // 0 ~ 255 ppm
    },
    pkg13: { // ref uplink pkg6: temp_offset_threshold
      temperatureThreshold: 30 // 0 ~ 255 unit 0.1 C
    },
    pkg14: { // ref uplink pkg6: co2_offset_threshold
      co2Threshold: 20 // 0 ~ 255 unit 10ppm
    },
    pkg15: { // ref uplink pkg6: nh3_offset_threshold
      anemometerThreshold: 10 // 0 ~ 255 0.1 m/s
    },
    // Adjust Sensor Type Parameter Package
    pkg16: { // ref uplink pkg5
      sensorAType: 0, // 0 ~ 255
      sensorBType: 0, // 0 ~ 255
      sensorCType: 0 // 0 ~ 255
    },
    noPackages: true
  }

  const [devices, setDevices] = useState(null)
  const [deviceDownlink, setDeviceDownlink] = useState(emptyDownlink)
  const [companyItems, setCompanyItems] = useState(null)
  const [companyMap, setCompanyMap] = useState(null)
  const [positions, setPositions] = useState(null)
  const [deviceDialog, setDeviceDialog] = useState(false)
  const [deviceDetailDialog, setDeviceDetailDialog] = useState(false)
  const [deviceDownlinkDialog, setDeviceDownlinkDialog] = useState(false)
  const [device, setDevice] = useState(emptyDevice)
  const [selectedDevices, setSelectedDevices] = useState(null)
  const [submitted, setSubmitted] = useState(false)
  const [globalFilter, setGlobalFilter] = useState(null)
  const toast = useRef(null)
  const dt = useRef(null)

  useEffect(() => {
    async function fetchData () {
      const deviceService = new DeviceService()
      const companyService = new CompanyService()
      const positionService = new PositionService()

      const [resDevices, resCompanies, resPositions] = await Promise.all(
        [deviceService.readDevices(),
          companyService.readCompanies(),
          positionService.readPositions()])
      setCompanyItems(resCompanies.map(c => ({ label: c.name, value: c.id })))
      const _companyMap = resCompanies.reduce((acc, c) => ({ ...acc, [c.id]: c }), {})
      setCompanyMap(_companyMap)
      setDevices(resDevices.map(d => ({ ...d, company: (_companyMap[d.companyId] ? _companyMap[d.companyId].name : '') })))
      setPositions(resPositions)
    }
    fetchData()
  }, [])

  const openNew = () => {
    setDevice(emptyDevice)
    setSubmitted(false)
    setDeviceDialog(true)
  }

  const hideDialog = () => {
    setSubmitted(false)
    setDeviceDialog(false)
  }

  const hideDeviceDetailDialog = () => {
    setDeviceDetailDialog(false)
  }

  const hideDeviceDownlinkDialog = () => {
    setDeviceDownlink(emptyDownlink)
    setDeviceDownlinkDialog(false)
  }

  const saveDevice = () => {
    setSubmitted(true)

    const _devices = [...devices]
    const _device = { ...device }

    const deviceService = new DeviceService()
    _device.service = companyMap[_device.companyId].service

    // NOTE: 補 device serivce 在 frontend 做, this will be deprecated

    if (device.companyId.trim() && device.hardware_serial.trim() &&
     device.serial_number.trim()) {
      if (!R.isNil(device.id)) {
        const index = findIndexById(device.id)

        // company name update
        _device.company = companyMap[_device.companyId].name

        _devices[index] = _device
        deviceService.updateDevice(_device).then(res => {
          toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Device Updated', life: 3000 })
        }).catch(e => {
          toast.current.show({ severity: 'error', summary: 'Failed', detail: `${e}`, life: 3000 })
        })
      } else {
        // create device
        deviceService.createDevice(_device).then(res => {
          _device.id = res.id
          _devices.push(_device)
          toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Device Created', life: 3000 })
        }).catch(e => {
          toast.current.show({ severity: 'error', summary: 'Failed', detail: `${e}`, life: 3000 })
        })
      }
      setDevices(_devices)
      setDeviceDialog(false)
      setDevice(emptyDevice)
    }
  }

  const doDownlink = (pkg) => {
    if (pkg === 'pkg16') {
      // check probe types, could not be same
      if (deviceDownlink.pkg16.sensorAType === deviceDownlink.pkg16.sensorBType ||
        deviceDownlink.pkg16.sensorAType === deviceDownlink.pkg16.sensorCType ||
        deviceDownlink.pkg16.sensorBType === deviceDownlink.pkg16.sensorCType) {
        toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Probe types could not be same.', life: 5000 })
        return
      }
    }
    console.log(`send ${device.hardware_serial} ${JSON.stringify(deviceDownlink[pkg])}`)
    const deviceService = new DeviceService()
    deviceService.downlink(device, pkg, deviceDownlink[`${pkg}`], (encodedPayload) => {
      toast.current.show({ severity: 'info', summary: 'Info', detail: `Send Downlink to ${device.hardware_serial} msg: ${JSON.stringify(encodedPayload)}`, life: 10000 })
    }).then(data => {
      console.log(data)
      if (data.errorCode === 0) {
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Send downlink success', life: 5000 })
      } else {
        toast.current.show({ severity: 'error', summary: 'Failed', detail: 'Send downlink failed', life: 5000 })
      }
    })
  }

  const editDevice = (device) => {
    setDevice({ ...device })
    setDeviceDialog(true)
  }

  const showDeviceDetail = (device) => {
    setDevice(device)
    setDeviceDetailDialog(true)
  }

  const showDeviceDownlink = (device) => {
    setDevice(device)
    const deviceService = new DeviceService()
    // NOTE: fetch device downlink info from server
    deviceService.getCurrentDeviceSetting(device).then(data => {
      console.log(data)
      if (data.errorCode === 0) {
        setDeviceDownlink({
          pkg5: { payload: data.pkg5.payload, time: data.pkg5.lora_time },
          pkg6: { payload: data.pkg6.payload, time: data.pkg6.lora_time },
          pkg7: { payload: data.pkg7.payload, time: data.pkg7.lora_time },
          pkg11: {
            regularMeasurementInterval: data.pkg7 ? data.pkg7.regular_measurement : emptyDownlink.pkg11.regularMeasurementInterval, // 0 ~ 255 minute
            statusReportInterval: data.pkg7 ? data.pkg7.status_report_interval : emptyDownlink.pkg11.statusReportInterval, // 0 ~ 65536 minute Big-Endian
            sleepN1: data.pkg7 ? data.pkg7.sleep_n1 : emptyDownlink.pkg11.sleepN1, // 0 ~ 255
            sleepN2: data.pkg7 ? data.pkg7.sleep_n2 : emptyDownlink.pkg11.sleepN2 // 0 ~ 255
          },
          pkg12: {
            nh3Threshold: data.pkg6 && data.pkg6.nh3_offset_threshold
          },
          pkg13: {
            temperatureThreshold: data.pkg6 && data.pkg6.temp_offset_threshold
          },
          pkg14: {
            co2Threshold: data.pkg6 && data.pkg6.co2_offset_threshold
          },
          pkg15: {
            anemometerThreshold: data.pkg6 && data.pkg6.anemometer_threshold
          },
          pkg16: {
            sensorAType: data.pkg5 && data.pkg5.probe_a_type, // 0 ~ 255
            sensorBType: data.pkg5 && data.pkg5.probe_b_type, // 0 ~ 255
            sensorCType: data.pkg5 && data.pkg5.probe_c_type // 0 ~ 255
          }
        })
      } else {
        setDeviceDownlink({ ...emptyDownlink })
      }

      setDeviceDownlinkDialog(true)
    })
  }

  const findIndexById = (id) => {
    let index = -1
    for (let i = 0; i < devices.length; i++) {
      if (devices[i].id === id) {
        index = i
        break
      }
    }

    return index
  }

  const exportCSV = () => {
    dt.current.exportCSV()
  }

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || ''
    const _device = { ...device }
    _device[`${name}`] = val

    setDevice(_device)
  }

  const changeDownlinkFields = (pkg, field, val) => {
    const _downlink = { ...deviceDownlink }
    _downlink[`${pkg}`][`${field}`] = val
    setDeviceDownlink(_downlink)
  }

  const onDownlinkInputChange = (e, pkg, name, min, max) => {
    const val = e.target.value === '' ? 0 : parseInt(e.target.value)
    if (!isNaN(val)) {
      if (val > max) {
        changeDownlinkFields(pkg, name, max)
      } else if (val < min) {
        changeDownlinkFields(pkg, name, min)
      } else {
        changeDownlinkFields(pkg, name, val)
      }
    }
  }

  const onSliderValue = (e, pkg, name) => {
    const val = parseInt(e.value)
    if (!isNaN(val)) {
      changeDownlinkFields(pkg, name, val)
    }
  }

  const unbindDevice = (rowData) => {
    if (R.isNil(rowData.positionId)) {
      toast.current.show({ severity: 'info', summary: 'Info', detail: 'Device is already unbinded, nothing to do', life: 3000 })
    } else {
      console.log(`unbind device ${rowData.id} from position ${rowData.positionId}`)
      console.log(positions)
      const foundPositions = positions.filter(p => p.id === rowData.positionId)
      console.log(foundPositions)
      if (foundPositions.length < 1) {
        toast.current.show({ severity: 'warn', summary: 'Failed', detail: 'Foud device is bind on nothing', life: 3000 })
      } else if (foundPositions > 1) {
        toast.current.show({ severity: 'warn', summary: 'Failed', detail: 'Found device is binding on multiple positions', life: 3000 })
      } else {
        const deviceService = new DeviceService()
        const foundPosition = foundPositions[0]
        deviceService.unbindDevice(rowData, foundPosition.id).then(res => {
          console.log(res)
          toast.current.show({ severity: 'info', summary: 'Success', detail: 'Unbind success', life: 3000 })
          const _devices = [...devices]
          const _device = { ...rowData }
          const index = findIndexById(_device.id)
          _devices[index] = res.device
          setDevices(_devices)
        })
      }
    }
  }

  const leftToolbarTemplate = () => {
    return (
      <>
        <div className='my-2'>
          <Button label='New' icon='pi pi-plus' className='p-button-success mr-2' onClick={openNew} />
        </div>
      </>
    )
  }

  const rightToolbarTemplate = () => {
    return (
      <>
        <FileUpload mode='basic' accept='image/*' maxFileSize={1000000} label='Import' chooseLabel='Import' className='mr-2 inline-block' />
        <Button label='Export' icon='pi pi-upload' className='p-button-help' onClick={exportCSV} />
      </>
    )
  }

  const serialNumberBodyTemplate = (rowData) => {
    return (
      <>
        <span className='p-column-title'>Serial Number</span>
        {rowData.serial_number}
      </>
    )
  }

  const remarkBodyTemplate = (rowData) => {
    return (
      <>
        <span className='p-column-title'>Remark</span>
        {rowData.remark}
      </>
    )
  }

  const companyBodyTemplate = (rowData) => {
    return (
      <>
        <span className='p-column-title'>Company</span>
        {/* {companyMap[rowData.companyId] ? companyMap[rowData.companyId].name : ''} */}
        {rowData.company}
      </>
    )
  }

  const serviceBodyTemplate = (rowData) => {
    return (
      <>
        <span className='p-column-title'>Service</span>
        {rowData.service}
      </>
    )
  }

  const hardwareSerialBodyTemplate = (rowData) => {
    return (
      <>
        <span className='p-column-title'>Hardware Serial</span>
        {rowData.hardware_serial}
      </>
    )
  }

  const positionBodyTemplate = (rowData) => {
    return (
      <>
        <span className='p-column-title'>Position</span>
        {R.isNil(rowData.positionId) ? '' : rowData.positionId}
      </>
    )
  }

  const actionBodyTemplate = (rowData) => {
    return (
      <div className='actions'>
        <Button icon='pi pi-info-circle' className='p-button-rounded p-button-info mt-2' onClick={() => showDeviceDetail(rowData)} />
        <Button icon='pi pi-pencil' className='p-button-rounded p-button-success mt-2' onClick={() => editDevice(rowData)} />
        {rowData.service === 'calyxsensingdatas'
          ? <Button icon='pi pi-link' className='p-button-rounded p-button-warn mt-3' onClick={() => showDeviceDownlink(rowData)} />
          : <div />}
      </div>
    )
  }

  const connectionBodyTemplate = (rowData) => {
    if (R.isNil(rowData.positionId)) {
      return (
        <div className='actions' />
      )
    } else {
      return (
        <div className='actions'>
          {/* <Button icon='pi pi-paperclip' className='p-button-rounded p-button-info mr-2' onClick={() => linkUnlinkDevice(rowData)} /> */}
          <Button icon='pi pi-link' className='p-button-rounded p-button-danger mt-2' onClick={() => unbindDevice(rowData)} />
        </div>
      )
    }
  }

  const parseProbType = (typeInt) => {
    switch (typeInt) {
      case 1:
        return 'CALYX_NH3'
      case 2:
        return 'EC_NH3'
      case 3:
        return 'DYNA_CO2'
      case 4:
        return 'SHT30'
      case 5:
        return 'SCD4X_CO2'
      case 6:
        return 'CYCFS1'
      default:
        return 'Unknown'
    }
  }

  const header = (
    <div className='flex flex-column md:flex-row md:justify-content-between md:align-items-center'>
      <h5 className='m-0'>Manage Devices</h5>
      <span className='block mt-2 md:mt-0 p-input-icon-left'>
        <i className='pi pi-search' />
        <InputText type='search' onInput={(e) => setGlobalFilter(e.target.value)} placeholder='Search...' />
      </span>
    </div>
  )

  const deviceDialogFooter = (
    <>
      <Button label='Cancel' icon='pi pi-times' className='p-button-text' onClick={hideDialog} />
      <Button label='Save' icon='pi pi-check' className='p-button-text' onClick={saveDevice} />
    </>
  )

  const formatUnixTime = (unixTime) => {
    const a = new Date(unixTime * 1000)
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    const year = a.getFullYear()
    const month = months[a.getMonth()]
    const date = a.getDate()
    const hour = a.getHours()
    const min = a.getMinutes()
    const sec = a.getSeconds()
    const time = year + '/' + month + '/' + date + '  ' + hour + ':' + min + ':' + sec
    return time
  }

  return (
    <div className='grid crud-demo'>
      <div className='col-12'>
        <div className='card'>
          <Toast ref={toast} />
          <Toolbar className='mb-4' left={leftToolbarTemplate} right={rightToolbarTemplate} />

          <DataTable
            ref={dt} value={devices} selection={selectedDevices} onSelectionChange={(e) => setSelectedDevices(e.value)}
            dataKey='id' paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className='datatable-responsive'
            paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'
            currentPageReportTemplate='Showing {first} to {last} of {totalRecords} devices'
            globalFilter={globalFilter} emptyMessage='No devices found.' header={header} responsiveLayout='scroll'
          >
            <Column selectionMode='multiple' headerStyle={{ width: '3rem' }} />
            {/* <Column field='id' header='id' sortable body={idBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }} /> */}
            <Column header='Serial Number' field='serial_number' sortable body={serialNumberBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }} />
            <Column header='Hardware Serial' field='hardware_serial' sortable body={hardwareSerialBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }} />
            <Column header='Remark' field='remark' sortable body={remarkBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }} />
            {/* service and company (name) will be deprecated */}
            <Column header='Company' field='company' sortable body={companyBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }} />
            <Column header='Service' field='service' sortable body={serviceBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }} />
            <Column header='Position' field='positionId' body={positionBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }} />
            <Column header='Unbind' body={connectionBodyTemplate} />
            <Column header='Action' body={actionBodyTemplate} />
          </DataTable>

          <Dialog visible={deviceDialog} style={{ width: '450px' }} header='Device Details' modal className='p-fluid' footer={deviceDialogFooter} onHide={hideDialog}>

            <div className='field'>
              <label htmlFor='serial_number'>Serial Number</label>
              <InputText
                id='serial_number' value={device.serial_number} onChange={(e) => onInputChange(e, 'serial_number')} required autoFocus
                disabled={device.id}
                className={classNames({ 'p-invalid': submitted && !device.serial_number })}
              />
              {submitted && !device.serial_number && <small className='p-invalid'>Serial Number is required.</small>}
            </div>

            <div className='field'>
              <label htmlFor='hardware_serial'>Hardware Serial</label>
              <InputText id='hardware_serial' value={device.hardware_serial} onChange={(e) => onInputChange(e, 'hardware_serial')} required autoFocus className={classNames({ 'p-invalid': submitted && !device.hardware_serial })} />
              {submitted && !device.hardware_serial && <small className='p-invalid'>Hardware Serial is required.</small>}
            </div>

            {/*
            <div className='field'>
              <label htmlFor='name'>Name</label>
              <InputText id='name' value={device.name} onChange={(e) => onInputChange(e, 'name')} required autoFocus className={classNames({ 'p-invalid': submitted && !device.name })} />
              {submitted && !device.name && <small className='p-invalid'>Name is required.</small>}
            </div>
            */}
            {/*
            <div className='field'>
              <label htmlFor='service'>Service</label>
              <Dropdown
                id='dropdown' value={device.service} options={serviceItems} onChange={(e) => onInputChange(e, 'service')}
                required autoFocus className={classNames({ 'p-invalid': submitted && !device.service })}
              />
              {submitted && !device.service && <small className='p-invalid'>Service is required.</small>}
            </div>
            */}
            <div className='field'>
              <label htmlFor='company'>Company</label>
              <Dropdown
                id='company' value={device.companyId} options={companyItems} onChange={(e) => onInputChange(e, 'companyId')}
                required className={classNames({ 'p-invalid': submitted && !device.companyId })}
              />
              {submitted && !device.companyId && <small className='p-invalid'>Compayn is required.</small>}
            </div>

            {/*
            <div className='field'>
              <label htmlFor='remark'>Remark</label>
              <InputText id='remark' value={device.remark} onChange={(e) => onInputChange(e, 'remark')} autoFocus className={classNames({ 'p-invalid': submitted && !device.remark })} />
            </div>
            */}
            <div className='field'>
              <label htmlFor='gateway'>Gateway</label>
              <InputText id='gateway' value={device.gateway} onChange={(e) => onInputChange(e, 'gateway')} autoFocus className={classNames({ 'p-invalid': submitted && !device.gateway })} />
            </div>

          </Dialog>

          <Dialog visible={deviceDetailDialog} style={{ width: '450px' }} header='Device Detail' modal onHide={hideDeviceDetailDialog}>
            <div className='field'>
              <label htmlFor='serial_number'>Serial Number: </label><label>{device.serial_number}</label>
            </div>
            <div className='field'>
              <label htmlFor='hardware_serial'>Hardware Serial: </label><label>{device.hardware_serial}</label>
            </div>
            {/* company field will be deprecated */}
            <div className='field'>
              <label htmlFor='mobile'>Company: </label><label>{device.companyId}</label>
            </div>
            <div className='field'>
              <label htmlFor='serivec'>Service: </label><label>{device.service}</label>
            </div>
            <div className='field'>
              <label htmlFor='mobile'>Position Id: </label><label>{device.positionId}</label>
            </div>
            <div className='field'>
              <label htmlFor='remark'>Remark: </label><label>{device.remark}</label>
            </div>
          </Dialog>

          <Dialog visible={deviceDownlinkDialog} style={{ width: '800px' }} className='p-fluid' header={`Device downlink ${deviceDownlink.noPackages ? '(No packages)' : ''}`} modal onHide={hideDeviceDownlinkDialog}>
            <TabView>
              <TabPanel header='Request'>
                <h1>Request Pagkages</h1>
                <div className='field'>
                  <label>
                    <h3 htmlFor='reqSysInfoPkg'>Request System Information Package</h3>
                  </label>
                  <div>
                    <label>
                      <h4>last uplink pkg5:</h4>
                      <h5>payload: {JSON.stringify(deviceDownlink.pkg5.payload)}</h5>
                      <h5>time: {formatUnixTime(deviceDownlink.pkg5.time)} ({deviceDownlink.pkg5.time}) </h5>
                    </label>
                    <Button label='Send Package' icon='pi' className='p-button-text' onClick={() => doDownlink('pkg5')} />
                  </div>
                </div>
                <div className='field'>
                  <label>
                    <h3 htmlFor='reqSysInfoPkg'>Request System Parameter Package</h3>
                  </label>
                  <div>
                    <label>
                      <h4>last uplink pkg6:</h4>
                      <h5>payload: {JSON.stringify(deviceDownlink.pkg6.payload)}</h5>
                      <h5>time: {formatUnixTime(deviceDownlink.pkg6.time)} ({deviceDownlink.pkg6.time})</h5>
                    </label>
                    <label>
                      <h4>last uplink pkg7:</h4>
                      <h5>payload: {JSON.stringify(deviceDownlink.pkg7.payload)}</h5>
                      <h5>time: {formatUnixTime(deviceDownlink.pkg7.time)} ({deviceDownlink.pkg7.time})</h5>
                    </label>
                    <Button label='Send Package' icon='pi' className='p-button-text' onClick={() => doDownlink('pkg6')} />
                  </div>
                </div>
              </TabPanel>
              <TabPanel header='Pkg 11'>
                <h1>Adjust Interval</h1>
                <div className='field'>
                  <label>
                    <h3 htmlFor='regularMeasurementInterval'>Regular Measurement Interval {`= ${deviceDownlink.pkg11.regularMeasurementInterval} min`}</h3>
                  </label>
                  <div>
                    <InputText value={deviceDownlink.pkg11.regularMeasurementInterval} onChange={(e) => onDownlinkInputChange(e, 'pkg11', 'regularMeasurementInterval', 1, 255)} />
                    <Slider min={1} max={255} value={deviceDownlink.pkg11.regularMeasurementInterval} onChange={(e) => onSliderValue(e, 'pkg11', 'regularMeasurementInterval')} />
                  </div>
                </div>
                <div className='field'>
                  <label>
                    <h3 htmlFor='statusReportInterval'>Status Report Interval {`= ${deviceDownlink.pkg11.statusReportInterval} min`}</h3>
                  </label>
                  <div>
                    <InputText value={deviceDownlink.pkg11.statusReportInterval} onChange={(e) => onDownlinkInputChange(e, 'pkg11', 'statusReportInterval', 0, 65535)} />
                    <Slider min={0} max={65536} value={deviceDownlink.pkg11.statusReportInterval} onChange={(e) => onSliderValue(e, 'pkg11', 'statusReportInterval')} />
                  </div>
                </div>
                <div className='field'>
                  <label>
                    <h3 htmlFor='sleepN1'>Sleep N1 {`= ${deviceDownlink.pkg11.sleepN1 * deviceDownlink.pkg11.regularMeasurementInterval} min`}</h3>
                  </label>
                  <div>
                    <InputText value={deviceDownlink.pkg11.sleepN1} onChange={(e) => onDownlinkInputChange(e, 'pkg11', 'sleepN1', 0, 255)} />
                    <Slider min={0} max={255} value={deviceDownlink.pkg11.sleepN1} onChange={(e) => onSliderValue(e, 'pkg11', 'sleepN1')} />
                  </div>
                </div>
                <div className='field'>
                  <label>
                    <h3 htmlFor='sleepN2'>Sleep N2 {`= ${deviceDownlink.pkg11.sleepN2 * deviceDownlink.pkg11.regularMeasurementInterval} min`}</h3>
                  </label>
                  <div>
                    <InputText value={deviceDownlink.pkg11.sleepN2} onChange={(e) => onDownlinkInputChange(e, 'pkg11', 'sleepN2', 0, 255)} />
                    <Slider min={0} max={255} value={deviceDownlink.pkg11.sleepN2} onChange={(e) => onSliderValue(e, 'pkg11', 'sleepN2')} />
                  </div>
                </div>
                <div className='field'>
                  <Button label='Send Package' icon='pi' className='p-button-text' onClick={() => doDownlink('pkg11')} />
                </div>
              </TabPanel>
              <TabPanel header='Pkg 12'>
                <h1>Adjust NH3 Threshold</h1>
                <div className='field'>
                  <label>
                    <h3 htmlFor='nh3Threshold'>NH3 Threshold {`= ${deviceDownlink.pkg12.nh3Threshold} ppm`}</h3>
                  </label>
                  <div>
                    <InputText value={deviceDownlink.pkg12.nh3Threshold} onChange={(e) => onDownlinkInputChange(e, 'pkg12', 'nh3Threshold', 0, 255)} />
                    <Slider min={0} max={255} value={deviceDownlink.pkg12.nh3Threshold} onChange={(e) => onSliderValue(e, 'pkg12', 'nh3Threshold')} />
                  </div>
                </div>
                <div className='field'>
                  <Button label='Send Package' icon='pi' className='p-button-text' onClick={() => doDownlink('pkg12')} />
                </div>
              </TabPanel>
              <TabPanel header='Pkg 13'>
                <h1>Adjust Temperature Threshold</h1>
                <div className='field'>
                  <label>
                    <h3 htmlFor='tempThreshold'>Temperature Threshold  {`= ${deviceDownlink.pkg13.temperatureThreshold / 10} °C`}</h3>
                  </label>
                  <div>
                    <InputText value={deviceDownlink.pkg13.temperatureThreshold} onChange={(e) => onDownlinkInputChange(e, 'pkg13', 'temperatureThreshold', 0, 255)} />
                    <Slider min={0} max={255} value={deviceDownlink.pkg13.temperatureThreshold} onChange={(e) => onSliderValue(e, 'pkg13', 'temperatureThreshold')} />
                  </div>
                </div>
                <div className='field'>
                  <Button label='Send Package' icon='pi' className='p-button-text' onClick={() => doDownlink('pkg13')} />
                </div>
              </TabPanel>
              <TabPanel header='Pkg 14'>
                <h1>Adjust CO2 Threshold</h1>
                <div className='field'>
                  <label>
                    <h3 htmlFor='co2Threshold'>CO2 Threshold  {`= ${deviceDownlink.pkg14.co2Threshold * 10} ppm`}</h3>
                  </label>
                  <div>
                    <InputText value={deviceDownlink.pkg14.co2Threshold} onChange={(e) => onDownlinkInputChange(e, 'pkg14', 'co2Threshold', 0, 255)} />
                    <Slider min={0} max={255} value={deviceDownlink.pkg14.co2Threshold} onChange={(e) => onSliderValue(e, 'pkg14', 'co2Threshold')} />
                  </div>
                </div>
                <div className='field'>
                  <Button label='Send Package' icon='pi' className='p-button-text' onClick={() => doDownlink('pkg14')} />
                </div>
              </TabPanel>
              <TabPanel header='Pkg 15'>
                <h1>Adjust Anemoter Threshold</h1>
                <div className='field'>
                  <label>
                    <h3 htmlFor='anemoterThreshold'>Anemoter Threshold  {`= ${deviceDownlink.pkg15.anemometerThreshold / 10} m/s`}</h3>
                  </label>
                  <div>
                    <InputText value={deviceDownlink.pkg15.anemometerThreshold} onChange={(e) => onDownlinkInputChange(e, 'pkg15', 'anemometerThreshold', 0, 255)} />
                    <Slider min={0} max={255} value={deviceDownlink.pkg15.anemometerThreshold} onChange={(e) => onSliderValue(e, 'pkg15', 'anemometerThreshold')} />
                  </div>
                </div>
                <div className='field'>
                  <Button label='Send Package' icon='pi' className='p-button-text' onClick={() => doDownlink('pkg15')} />
                </div>
              </TabPanel>
              <TabPanel header='Pkg 16'>
                <h1>Adjust Sensor Type Parameter</h1>
                <div className='field'>
                  <label>
                    <h3 htmlFor='nh3Threshold'>Sensor A Type = {parseProbType(deviceDownlink.pkg16.sensorAType)}</h3>
                  </label>
                  <div>
                    <InputText value={deviceDownlink.pkg16.sensorAType} onChange={(e) => onDownlinkInputChange(e, 'pkg16', 'sensorAType', 1, 6)} />
                    <Slider min={1} max={6} value={deviceDownlink.pkg16.sensorAType} onChange={(e) => onSliderValue(e, 'pkg16', 'sensorAType')} />
                  </div>
                </div>
                <div className='field'>
                  <label>
                    <h3 htmlFor='nh3Threshold'>Sensor B Type = {parseProbType(deviceDownlink.pkg16.sensorBType)}</h3>
                  </label>
                  <div>
                    <InputText value={deviceDownlink.pkg16.sensorBType} onChange={(e) => onDownlinkInputChange(e, 'pkg16', 'sensorBType', 1, 6)} />
                    <Slider min={1} max={6} value={deviceDownlink.pkg16.sensorBType} onChange={(e) => onSliderValue(e, 'pkg16', 'sensorBType')} />
                  </div>
                </div>
                <div className='field'>
                  <label>
                    <h3 htmlFor='nh3Threshold'>Sensor C Type = {parseProbType(deviceDownlink.pkg16.sensorCType)}</h3>
                  </label>
                  <div>
                    <InputText value={deviceDownlink.pkg16.sensorCType} onChange={(e) => onDownlinkInputChange(e, 'pkg16', 'sensorCType', 1, 6)} />
                    <Slider min={1} max={6} value={deviceDownlink.pkg16.sensorCType} onChange={(e) => onSliderValue(e, 'pkg16', 'sensorCType')} />
                  </div>
                </div>
                <div className='field'>
                  <Button label='Send Package' icon='pi' className='p-button-text' onClick={() => doDownlink('pkg16')} />
                </div>
              </TabPanel>
            </TabView>
          </Dialog>
        </div>
      </div>
    </div>
  )
}
