import axios from 'axios'
import env from '../env'

export class UserService {
  constructor () {
    const store = JSON.parse(window.localStorage.getItem('calyx-tools') || '{}')
    this.token = store.token
  }

  async getUsers () {
    return axios.get(env.apiDomain + '/apiv2/users', { headers: { Authorization: this.token } })
      .then(res => {
        return res.data
      })
  }

  async updateUser (user) {
    Object.keys(user).forEach(key => user[key] === undefined && delete user[key])
    return axios.patch(env.apiDomain + `/apiv2/users/${user.id}`, {
      // TODO
      ...user
    }, { headers: { Authorization: this.token } })
      .then(res => {
        return res.data
      })
  }

  async createUser (user) {
    Object.keys(user).forEach(key => user[key] === undefined && delete user[key])
    return axios.post(env.apiDomain + '/apiv2/users', {
      // TODO
      ...user
    }, { headers: { Authorization: this.token } })
      .then(res => {
        return res.data
      })
  }
}
