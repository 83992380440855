import React from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { Menu } from 'primereact/menu'

export const AppTopbar = (props) => {
  const [profileMenu, setProfileMenu] = React.useState(false)

  const menuitems = [
    {
      label: 'User',
      items: [
        {
          label: 'Profile',
          icon: 'pi pi-fw pi-plus'
        },
        {
          label: 'Setting',
          icon: 'pi pi-fw pi-user-edit'
        }
      ]
    },
    {
      label: 'Action',
      items: [
        {
          label: 'Logout',
          icon: 'pi pi-fw pi-list',
          command: () => {
            window.localStorage.clear()
            window.location = '/'
          }
        }
      ]
    }
  ]

  const clickProfileBtn = (e) => {
    props.onMobileSubTopbarMenuClick(e)
    setProfileMenu(!profileMenu)
  }

  return (
    <div className='layout-topbar'>
      <Link to='/' className='layout-topbar-logo'>
        <img src={props.layoutColorMode === 'light' ? 'assets/layout/images/calyxlogo.png' : 'assets/layout/images/calyxlogo.png'} alt='logo' />
        <span>Calyx</span>
      </Link>

      <button type='button' className='p-link  layout-menu-button layout-topbar-button' onClick={props.onToggleMenuClick}>
        <i className='pi pi-bars' />
      </button>

      <button type='button' className='p-link layout-topbar-menu-button layout-topbar-button' onClick={props.onMobileTopbarMenuClick}>
        <i className='pi pi-ellipsis-v' />
      </button>

      <ul className={classNames('layout-topbar-menu lg:flex origin-top', { 'layout-topbar-menu-mobile-active': props.mobileTopbarMenuActive })}>
        <li>
          <button className='p-link layout-topbar-button' onClick={props.onMobileSubTopbarMenuClick}>
            <i className='pi pi-calendar' />
            <span>Events</span>
          </button>
        </li>
        <li>
          <button className='p-link layout-topbar-button' onClick={props.onMobileSubTopbarMenuClick}>
            <i className='pi pi-cog' />
            <span>Settings</span>
          </button>
        </li>
        <li>
          <button className='p-link layout-topbar-button' onClick={clickProfileBtn}>
            <i className='pi pi-user' />
            <span>Profile</span>
          </button>

        </li>
      </ul>
      <Menu style={{ position: 'fixed', zIndex: 3, right: '1rem', top: '70px', display: (profileMenu ? 'block' : 'none') }} model={menuitems} />
    </div>
  )
}
