export const todos = JSON.parse(window.localStorage.getItem('calyx-tools') || '{}')

const reducer = (state, action) => {
  const { type, payload, callback } = action
  console.log(`reducer got action = ${JSON.stringify(action)}`)
  switch (type) {
    case 'ADD': {
      // const newState = [...state, payload]
      const newState = { ...state }
      window.localStorage.setItem('calyx-tools', JSON.stringify(newState))
      // return [...state, payload]
      return { ...state }
    }
    case 'DELETE':
      return state.filter(todo => todo !== payload)
    case 'LOGIN': {
      console.log(payload)
      const newState = { ...state, isLogin: true, token: payload.token, user: payload.user }
      window.localStorage.setItem('calyx-tools', JSON.stringify(newState))
      // TODO callback should be put other place
      /* if (callback) callback() */
      return { ...newState }
    }
    default:
      return state
  }
}

export default reducer
