
export const roleGod = 0
export const roleFreeVisitor = 1
export const roleFreeAdmin = 2
export const roleStandardVisitor = 3
export const roleStandardAdmin = 4
export const roleStandardSuperAdmin = 5
export const roleProVisitor = 6
export const roleProAdmin = 7
export const roleProSuperAdmin = 8
export const roleTrailVisitor = 9
export const roleTrailAdmin = 10
export const roleTrailSuperAdmin = 11

export const userRoles = {
  [roleGod]: 'God',
  [roleFreeVisitor]: 'Free Visitor',
  [roleFreeAdmin]: 'Free Admin',
  [roleStandardVisitor]: 'Standard Visitor',
  [roleStandardAdmin]: 'Standard Admin',
  [roleStandardSuperAdmin]: 'Standard Super Admin',
  [roleProVisitor]: 'Pro Visitor',
  [roleProAdmin]: 'Pro Admin',
  [roleProSuperAdmin]: 'Pro Super Admin',
  [roleTrailVisitor]: 'Trail Visitor',
  [roleTrailAdmin]: 'Trail Admin',
  [roleTrailSuperAdmin]: 'Trail Super Admin'
}

export const transRole = (roleNumber) => {
  const roleDesc = userRoles[roleNumber]
  return roleDesc || 'None'
}
