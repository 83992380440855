import React, { useState, useEffect, useRef } from 'react'
import classNames from 'classnames'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Toast } from 'primereact/toast'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { Toolbar } from 'primereact/toolbar'
import { RadioButton } from 'primereact/radiobutton'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import { CompanyService } from '../service/CompanyService'
import { serviceItems } from '../serviceItems'
import {
  idBodyTemplate, nameBodyTemplate,
  isDealerBodyTemplate, serviceBodyTemplate,
  trailBodyTemplate
} from '../components/ColumnBodyTemplate'

export const CrudCompany = () => {
  const emptyCompany = {
    id: null,
    name: '',
    isdealer: false,
    service: '',
    createdAt: '',
    deletedAt: '',
    isTrail: false
  }

  const [companies, setCompanies] = useState(null)
  const [companyDialog, setCompanyDialog] = useState(false)
  const [companyDetailDialog, setCompanyDetailDialog] = useState(false)
  const [company, setCompany] = useState(emptyCompany)

  const [selectedCompanies, setSelectedCompanies] = useState(null)
  const [submitted, setSubmitted] = useState(false)
  const [globalFilter, setGlobalFilter] = useState(null)
  const toast = useRef(null)
  const dt = useRef(null)

  useEffect(() => {
    async function fetchData () {
      const companyService = new CompanyService()
      const resCompanies = await companyService.readCompanies()
      setCompanies(resCompanies)
    }
    fetchData()
  }, [])

  const openNew = () => {
    setCompany(emptyCompany)
    setSubmitted(false)
    setCompanyDialog(true)
  }

  const hideDialog = () => {
    setSubmitted(false)
    setCompanyDialog(false)
  }

  const hideDetailDialog = () => {
    setCompanyDetailDialog(false)
  }

  const saveCompany = () => {
    setSubmitted(true)
    const companyService = new CompanyService()
    if (company.name.trim()) {
      const _companies = [...companies]
      const _company = { ...company }
      if (company.id) {
        const index = findIndexById(company.id)

        _companies[index] = _company
        // TODO call update api
        companyService.updateCompany(_company).then(data => {
          toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Company Updated', life: 3000 })
          setCompanies(_companies)
          setCompany(emptyCompany)
        }).catch(e => {
          toast.current.show({ severity: 'error', summary: 'Failed', detail: `${e}`, life: 3000 })
        })
      } else {
        // TODO call create api
        _companies.push(_company)

        companyService.createCompany(company).then(data => {
          toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Company Created', life: 3000 })
          setCompanies(_companies)
          setCompany(emptyCompany)
        }).catch(e => {
          toast.current.show({ severity: 'error', summary: 'Failed', detail: `${e}`, life: 3000 })
        })
      }
    }
    setCompanyDialog(false)
  }

  const editCompany = (company) => {
    setCompany({ ...company })
    setCompanyDialog(true)
  }

  const showCompanyDetail = (company) => {
    setCompany({ ...company })
    setCompanyDetailDialog(true)
  }

  const findIndexById = (id) => {
    let index = -1
    for (let i = 0; i < companies.length; i++) {
      if (companies[i].id === id) {
        index = i
        break
      }
    }

    return index
  }

  const exportCSV = () => {
    dt.current.exportCSV()
  }

  const onIsDealerChange = (e) => {
    const _company = { ...company }
    _company.isdealer = (e.value === 'true')
    setCompany(_company)
  }

  const onIsTrailChange = (e) => {
    const _company = { ...company }
    _company.isTrail = e.value === 'true'
    setCompany(_company)
  }

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || ''
    const _company = { ...company }
    _company[`${name}`] = val

    setCompany(_company)
  }

  const leftToolbarTemplate = () => {
    return (
      <>
        <div className='my-2'>
          <Button label='New' icon='pi pi-plus' className='p-button-success mr-2' onClick={openNew} />
        </div>
      </>
    )
  }

  const rightToolbarTemplate = () => {
    return (
      <>
        <Button label='Export' icon='pi pi-upload' className='p-button-help' onClick={exportCSV} />
      </>
    )
  }

  const actionBodyTemplate = (rowData) => {
    return (
      <div className='actions'>
        <Button icon='pi pi-info-circle' className='p-button-rounded p-button-info mr-2' onClick={() => showCompanyDetail(rowData)} />
        <Button icon='pi pi-pencil' className='p-button-rounded p-button-success mr-2' onClick={() => editCompany(rowData)} />
      </div>
    )
  }

  const header = (
    <div className='flex flex-column md:flex-row md:justify-content-between md:align-items-center'>
      <h5 className='m-0'>Manage Products</h5>
      <span className='block mt-2 md:mt-0 p-input-icon-left'>
        <i className='pi pi-search' />
        <InputText type='search' onInput={(e) => setGlobalFilter(e.target.value)} placeholder='Search...' />
      </span>
    </div>
  )

  const companyDialogFooter = (
    <>
      <Button label='Cancel' icon='pi pi-times' className='p-button-text' onClick={hideDialog} />
      <Button label='Save' icon='pi pi-check' className='p-button-text' onClick={saveCompany} />
    </>
  )

  const companyDetailDialogFooter = (
    <>
      <Button label='Cancel' icon='pi pi-times' className='p-button-text' onClick={hideDetailDialog} />
    </>
  )

  return (
    <div className='grid crud-demo'>
      <div className='col-12'>
        <div className='card'>
          <Toast ref={toast} />
          <Toolbar className='mb-4' left={leftToolbarTemplate} right={rightToolbarTemplate} />

          <DataTable
            ref={dt} value={companies} selection={selectedCompanies} onSelectionChange={(e) => setSelectedCompanies(e.value)}
            dataKey='id' paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className='datatable-responsive'
            paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'
            currentPageReportTemplate='Showing {first} to {last} of {totalRecords} products'
            globalFilter={globalFilter} emptyMessage='No companies found.' header={header} responsiveLayout='scroll'
          >
            <Column selectionMode='multiple' headerStyle={{ width: '3rem' }} />
            <Column field='id' header='id' sortable body={idBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }} />
            <Column field='name' header='Name' sortable body={nameBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }} />
            <Column field='service' header='service' sortable body={serviceBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }} />
            <Column field='isDealer' header='isDealer' body={isDealerBodyTemplate} headerStyle={{ width: '5%', minWidth: '2rem' }} />
            <Column field='trail' header='trail' body={trailBodyTemplate} headerStyle={{ width: '5%', minWidth: '2rem' }} />
            {/* <Column field='createdAt' header='createdAt' sortable body={createTimeBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }} /> */}
            {/* <Column field='updatedAt' header='updatedAt' sortable body={updateTimeBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }} /> */}
            <Column body={actionBodyTemplate} />
          </DataTable>

          <Dialog visible={companyDialog} style={{ width: '450px' }} header='Company Details' modal className='p-fluid' footer={companyDialogFooter} onHide={hideDialog}>
            <div className='field'>
              <label htmlFor='name'>Name</label>
              <InputText id='name' value={company.name} onChange={(e) => onInputChange(e, 'name')} required autoFocus className={classNames({ 'p-invalid': submitted && !company.name })} />
              {submitted && !company.name && <small className='p-invalid'>Name is required.</small>}
            </div>

            <div className='field'>
              <label htmlFor='name'>Service</label>
              <Dropdown
                id='dropdown' value={company.service} options={serviceItems} onChange={(e) => onInputChange(e, 'service')}
                required autoFocus className={classNames({ 'p-invalid': submitted && !company.service })}
              />
              {submitted && !company.service && <small className='p-invalid'>Service is required.</small>}
            </div>

            <div className='field'>
              <label className='mb-3'>Is Dealer</label>
              <div className='formgrid grid'>
                <div className='field-radiobutton col-6'>
                  <RadioButton inputId='isDealerTrue' name='isDealer' value='true' onChange={onIsDealerChange} checked={company.isdealer === true} />
                  <label htmlFor='isDealerTrue'>True</label>
                </div>
                <div className='field-radiobutton col-6'>
                  <RadioButton inputId='isDealerFalse' name='isDealer' value='false' onChange={onIsDealerChange} checked={company.isdealer === false} />
                  <label htmlFor='isDealerFalse'>False</label>
                </div>
              </div>
            </div>
            <div className='field'>
              <label className='mb-3'>Is Trail</label>
              <div className='formgrid grid'>
                <div className='field-radiobutton col-6'>
                  <RadioButton inputId='isTrailTrue' name='isTrail' value='true' onChange={onIsTrailChange} checked={company.isTrail === true} />
                  <label htmlFor='isTrailTrue'>True</label>
                </div>
                <div className='field-radiobutton col-6'>
                  <RadioButton inputId='isTrailFalse' name='isTrail' value='false' onChange={onIsTrailChange} checked={company.isTrail === false || company.isTrail === undefined} />
                  <label htmlFor='isTrailFalse'>False</label>
                </div>
              </div>
            </div>
          </Dialog>

          <Dialog visible={companyDetailDialog} style={{ width: '450px' }} header='Company Detail' modal className='p-fluid' footer={companyDetailDialogFooter} onHide={hideDetailDialog}>
            <div className='field'>
              <label htmlFor='name'>Company Name: </label> <label>{company.name}</label>
            </div>
            <div className='field'>
              <label htmlFor='service'>Service Name: </label> <label>{company.service}</label>
            </div>
            <div className='field'>
              <label htmlFor='awsCert'>AWS Cert: </label> <label>{company.awsIoTCertNo}</label>
            </div>
            <div className='field'>
              <label htmlFor='dealer'>Is Dealer: </label> <label>{company.isdealer ? 'True' : 'False'}</label>
            </div>
            {/*  TODO trail need to redesign
            <div className='field'>
              <label htmlFor='trail'>Is Trail: </label> <label>{company.isTrail ? 'True' : 'False'}</label>
            </div>
             */}
            <div className='field'>
              <label htmlFor='createdTime'>Created At: </label> <label>{company.createdAt}</label>
            </div>
            <div className='field'>
              <label htmlFor='updatedTime'>Updated At: </label> <label>{company.updatedAt}</label>
            </div>
          </Dialog>
        </div>
      </div>
    </div>
  )
}
