import axios from 'axios'
import env from '../env'

export class JHControllerService {
  constructor () {
    const store = JSON.parse(window.localStorage.getItem('calyx-tools') || '{}')
    this.token = store.token
  }
  
  async createJHController (company) {
    return axios.post(env.apiDomain + '/api/JHControllers', {
      service: company.service,
      name: company.name,
      isDealer: company.isDealer
    }, { headers: { Authorization: this.token } })
      .then(res => {
        return res.data
      })
  }

  async readJHControllers () {
    return axios.get(env.apiDomain + '/api/JHControllers', { headers: { Authorization: this.token } })
      .then(res => {
        console.log(res)
        return res.data
      })
  }

  async updateJHController (company) {
    return axios.patch(env.apiDomain + `/api/JHControllers?_id=${company.id}`, {
      service: company.service,
      name: company.name,
      isDealer: company.isDealer
    }, { headers: { Authorization: this.token } })
      .then(res => {
        return res.data
      })
  }
}
